

























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import Smartcard from "@/models/smartcard";
import { mdiCheck, mdiContentCopy, mdiTelevisionClassic } from "@mdi/js";
import { SelectItem } from "@/types/veutify";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import PriceApi from "@/services/api/prices";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import UserProfile from "@/models/user-profile";
import { isWebView } from "@/plugins/utils";
import DStvApi, { PayDStvInput } from "@/services/api/dstv";
import { ApiResponse } from "@/services/api/axios";

interface DStvOrder {
    id: string;
    cost_price: number;
    sale_price: number;
    dstv_package_id: string;
    smartcard: string;
}

@Component({
    components: {
        CompositeAlert,
    },
    metaInfo() {
        let image = "https://nyangapay.com/img/header-1.png";
        if (window.location.href.indexOf("invite=") != -1) {
            image = "https://nyangapay.com/img/invite-header.png";
        }

        let title =
            "Pay your DStv subscription online using MTN Mobile Money or Orange Money";
        if (window.location.href.indexOf("invite=") != -1) {
            title = "Get 1,000 FCFA discount for your first DStv subscription";
        }

        let description =
            "Pay your DStv subscription online using MTN Mobile Money or Orange Money";
        if (window.location.href.indexOf("invite=") != -1) {
            description =
                "Use this link to get 1,000 FCFA discount for your first DStv subscription on NyangaPay.com using Orange Money or Mobile Money";
        }

        return {
            title,
            meta: [
                {
                    name: "og:image",
                    content: image,
                },
                {
                    name: "twitter:image",
                    content: image,
                },
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class DStvShow extends Vue {
    formEmail: string = "";
    tickIcon: string = mdiCheck;
    formSmartCardNumber: string = "";
    formPaymentOption: string = "";
    formPhoneNumber: string = "";
    tvIcon: string = mdiTelevisionClassic;
    formDstvPackage: string = "CB177-new";
    copyIcon: string = mdiContentCopy;
    formPriceLock = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    smartcard: Smartcard | null = null;
    dstvPackages: Array<SelectItem> = [];
    order: DStvOrder | null = null;

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdminOrCustomerServiceAgent")
    userIsAdminOrCustomerServiceAgent!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    isNotAffectedByPriceLock(packageID: string): boolean {
        return packageID.includes("TEST") || packageID.includes("CUSTOM");
    }

    get isWebView(): boolean {
        return isWebView();
    }

    get payUsingBankCard(): boolean {
        return this.formPaymentOption == "bank-card";
    }

    get formPhoneNumberLabel(): string {
        if (!this.formPaymentOption) {
            return "Payment Phone Number";
        }

        if (this.formPaymentOption == "mtn-mobile-money") {
            return "MTN Mobile Money Phone Number";
        }
        return "Orange Money Phone Number";
    }

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentOption) {
            return "Phone number e.g 6xxxxxxxx";
        }
        return `${this.paymentMethodString} number e.g 6xxxxxxxx`;
    }

    get paymentMethodString(): string {
        return this.$options.filters?.capitalize(
            this.formPaymentOption?.split("-").join(" ")
        );
    }

    get isCustomPackage(): boolean {
        return this.formDstvPackage === "CUSTOM";
    }

    get activeDStvPackages(): Array<SelectItem> {
        if (this.formPriceLock) {
            return this.dstvPackages.filter(
                (item) =>
                    !item.value.toString().includes("-new") ||
                    this.isNotAffectedByPriceLock(item.value as string)
            );
        }

        return this.dstvPackages.filter(
            (item) =>
                item.value.toString().includes("-new") ||
                this.isNotAffectedByPriceLock(item.value as string)
        );
    }

    async copyOrderLink() {
        try {
            addAnalyticsEvent("copy_order_link_clicked");
            await navigator.clipboard.writeText(location.href);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                `The custom order URL has been copied`
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    handleCustomOrder() {
        addAnalyticsEvent("pay_subscription_clicked");
        this.isSubmitting = true;

        this.$recaptcha("pay_custom_order")
            .then((token: string) => {
                const payload: PayDStvInput = {
                    email: this.formEmail,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentOption,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    captcha: token,
                };
                this.ensureUserExists()
                    .then(() => {
                        this.payCustomOrder(payload);
                    })
                    .catch(this.handleSubmittingError);
            })
            .catch(this.handleSubmittingError);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSubmittingError(error: any) {
        this.isSubmitting = false;
        this.handleError(error);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formPaymentOption = userProfile.paymentMethod ?? "";
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.user?.email ?? "";
            })
            .catch((error: AxiosError<ApiResponse>) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    loadPrices() {
        PriceApi.indexDstv()
            .then((response: AxiosResponse) => {
                this.dstvPackages = response.data.data;
            })
            .catch(captureSentryException);
    }

    loadOrder() {
        DStvApi.get(this.$route.params.orderId)
            .then((response: AxiosResponse) => {
                this.order = response.data.data;
                this.formSmartCardNumber =
                    this.order?.smartcard ?? this.formSmartCardNumber;
                this.formDstvPackage =
                    this.order?.dstv_package_id ?? this.formDstvPackage;
            })
            .catch((error: AxiosError<ApiResponse>) => {
                captureSentryException(error);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.ERROR,
                    "This custom order does not exist"
                );
                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.DSTV_INDEX,
                });
            });
    }

    mounted() {
        this.loadPrices();
        this.loadOrder();
        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }

        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    payCustomOrder(payload: PayDStvInput) {
        DStvApi.pay(this.order?.id ?? "", payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "XAF",
                    value: order.paymentAmount,
                    coupon: order.referralCode,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: "DStv",
                            coupon: order.referralCode,
                            currency: "XAF",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
