import { AxiosResponse } from "axios";
import Axios from "./axios";

export interface CreateDStvInput {
    captcha: string;
    dstv_package: string;
    cost_price: number;
    sale_price: number;
    smartcard_number: string;
}

export interface PayDStvInput {
    captcha: string;
    email: string;
    payment_method: string;
    phone_number: string | null;
}

class DStv {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    create(order: CreateDStvInput): Promise<AxiosResponse> {
        return this.axios.post(`/dstv/`, order);
    }

    pay(orderId: string, request: PayDStvInput): Promise<AxiosResponse> {
        return this.axios.post(`/dstv/${orderId}`, request);
    }

    get(orderId: string): Promise<AxiosResponse> {
        return this.axios.get(`/dstv/${orderId}`);
    }
}

export default new DStv(Axios);
